<template>
  <Menubar :model="items">
    <template #start>
      <img alt="logo" :src="logo" height="40" class="p-mr-2" />
    </template>
    <template #end>
      <Button
        label="تسجل خروج"
        @click="logout"
        icon="pi pi-power-off"
        class="p-button-danger"
      />
    </template>
  </Menubar>
</template>

<script>
import Menubar from 'primevue/menubar';
export default {
  components: {
    Menubar,
  },
  name: 'app-header',
  data() {
    return {
      items: [
        {
          label: 'الرئيسيه',
          icon: 'pi pi-fw pi-home',
          to: '/admin',
        },
      ],
      logo: null,
    };
  },
  methods: {
    logout() {
      delete localStorage.admin;
      location.reload();
    },
    getLeague() {
      this.$http
        .post(`league/search`, {
          select: ['image', 'name'],
          where: {
            status: true,
          },
          order: {
            id: 'DESC',
          },
        })
        .then((res) => {
          const list = res.data;
          if (list.length > 0) {
            this.logo = this.$baseUploadURL + list[0].image;
            this.leagueName = list[0].name;
            this.items[0].label = `الرئيسيه (${this.leagueName})`;
          } else {
            this.logo = '/images/logo.png';
          }
        });
    },
  },
  created() {
    this.getLeague();
    const user = JSON.parse(localStorage.admin);
    const roles = user.roles ?? JSON.parse(user.roles);
    if (this.$checkRoles('logsVeiw')) {
      this.items.push({
        label: 'المرقبه',
        icon: 'pi pi-fw pi-shield',
        to: '/admin/logs',
      });
    }

    if (this.$checkRoles('adminsVeiw')) {
      this.items.push({
        label: 'المديرين',
        icon: 'pi pi-fw pi-user',
        to: '/admin/admins',
      });
    }

    if (this.$checkRoles('leagueVeiw')) {
      this.items.push({
        label: 'المسابقات',
        icon: 'pi pi-map-marker',
        to: '/admin/league',
      });
    }

    if (this.$checkRoles('leagueVeiw')) {
      this.items.push({
        label: 'المواليد المشاركه',
        icon: 'pi pi-fw pi-calendar-plus',
        to: '/admin/years',
      });
    }

    if (
      this.$checkRoles('academiesVeiw') ||
      this.$checkRoles('coachVeiw') ||
      this.$checkRoles('mindfulVeiw') ||
      this.$checkRoles('technicalManagerVeiw') ||
      this.$checkRoles('playersVeiw')
    ) {
      const list = [];

      if (this.$checkRoles('academiesVeiw')) {
        list.push({
          label: 'الاكاديميات',
          icon: 'pi pi-fw pi-sitemap',
          to: '/admin/academies',
        });
      }
      if (this.$checkRoles('coachVeiw')) {
        list.push({
          label: 'المدربين',
          icon: 'pi pi-fw pi-ticket',
          to: '/admin/coach',
        });
      }

      if (this.$checkRoles('mindfulVeiw')) {
        list.push({
          label: 'الادريين',
          icon: 'pi pi-fw pi-star-o',
          to: '/admin/mindful',
        });
      }

      if (this.$checkRoles('technicalManagerVeiw')) {
        list.push({
          label: 'المدرين الفنين',
          icon: 'pi pi-fw pi-link',
          to: '/admin/technical_manager',
        });
      }

      if (this.$checkRoles('playersVeiw')) {
        list.push({
          label: 'اللاعبين',
          icon: 'pi pi-fw pi-users',
          to: '/admin/players',
        });
      }

      // if (this.$checkRoles('movePlayersVeiw')) {
      //   list.push({
      //     label: 'نقل اللاعبين',
      //     icon: 'pi pi-fw pi-sort-alt',
      //     to: '/admin/players-move',
      //   });
      // }

      this.items.push({
        label: 'الاكاديميات',
        icon: 'pi pi-fw pi-sitemap',
        items: list,
      });
    }

    if (
      this.$checkRoles('carnicsVeiw') ||
      this.$checkRoles('ascorchitVeiw') ||
      // comant
      this.$checkRoles('mindfulVeiw') ||
      this.$checkRoles('technicalManagerVeiw') ||
      this.$checkRoles('playersVeiw')
    ) {
      const list = [];

      if (this.$checkRoles('carnicsVeiw')) {
        list.push({
          label: 'الكارنيهات',
          icon: 'pi pi-fw pi-id-card',
          to: '/admin/carnics',
        });
      }
      if (this.$checkRoles('certificatesVeiw')) {
        list.push({
          label: 'الشهادات',
          icon: 'pi pi-fw pi-image',
          to: '/admin/certificates',
        });
      }

      if (this.$checkRoles('ascorchitVeiw')) {
        list.push({
          label: 'اسكورشيت',
          icon: 'pi pi-fw pi-list',
          to: '/admin/ascorchit',
        });
      }
      if (this.$checkRoles('organizersVeiw')) {
        list.push({
          label: 'المنظمين',
          icon: 'pi pi-fw pi-table',
          to: '/admin/organizers',
        });
      }

      // if (this.$checkRoles('championshipVeiw')) {
      //   list.push({
      //     label: 'نتائج البطوله',
      //     icon: 'pi pi-fw pi-sort',
      //     to: '/admin/score',
      //   });
      // }

      if (this.$checkRoles('newsVeiw')) {
        list.push({
          label: 'الأخبار',
          icon: 'pi pi-fw pi-tag',
          to: '/admin/news',
        });
      }

      if (this.$checkRoles('softVeiw')) {
        list.push({
          label: 'استرجاع البيانات',
          icon: 'pi pi-fw pi-tag',
          to: '/soft/admins',
        });
      }

      this.items.push({
        label: 'المزيد',
        icon: 'pi pi-fw pi-th-large',
        items: list,
      });
    }
  },
};
</script>
